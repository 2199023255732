import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { convertDate } from '../../functions/formating/convertDate';
import Button from '../common/Button';
import H3 from '../typography/H3';
import P from '../typography/P';

const PodcastListEpisodes = ({ listArray, className }) => {
  return (
    <div className={className ? className : ''}>
      {listArray.map((item, i) => {
        return (
          <div className={`md:grid md:grid-cols-10 ${listArray.length === i + 1 ? '' : 'mb-8'}`} key={i}>
            <div className={`md::mb-0 col-span-4 mb-3 `}>
              <Link to={`/podcast/folgen/${item.folgennummer}/${item.slug_slug}/`}>
                <GatsbyImage image={getImage(item.remoteTitelbild)} className="w-full rounded-xl align-top duration-300 hover:scale-105" alt={item.titel} />
              </Link>
            </div>
            <div className={`col-span-6 flex flex-col  pl-3`}>
              <div className="mb-3 text-base text-gray-500">
                Folge: {item.folgennummer} - {convertDate(item.datum)} - Thema: <span>{item.thema.display}</span>
              </div>

              <div className="mb-5">
                <Link to={`/podcast/folgen/${item.folgennummer}/${item.slug_slug}/`} className="hover:underline">
                  <H3 className={`font-display text-brand-gray`}>{item.titel}</H3>
                </Link>
              </div>

              <P className="mb-3 ">{item.einleitung}</P>
              <div className="inline-block">
                <Button text="Zur Folge" to={`/podcast/folgen/${item.folgennummer}/${item.slug_slug}/`} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PodcastListEpisodes;
