import React from 'react';
interface Props {
  children: React.ReactNode;
  as?: string;
  className?: string;
}

const H3: React.FC<Props> = ({ children, as = 'h1', className }) => {
  const classes = `text-lg font-display sm:text-xl md:text-2xl ${className ? className : ''}`;
  return React.createElement(as, { className: classes }, children);
};

export default H3;
