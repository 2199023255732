import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import H4 from '../typography/H4';

const SidebarTopics = ({ current }) => {
  const data = useStaticQuery(graphql`
    query {
      allPodcastThemen {
        nodes {
          thema
          farbe
          slug_slug
        }
      }

      allPodcastFolgen(filter: { public: { eq: true } }) {
        nodes {
          thema {
            display
          }
        }
      }
    }
  `);

  const colorMapping = {
    'topic-red': '#dc2626',
    'topic-yellow': '#fbbf24',
    'topic-green': '#059669',
    'topic-blue': '#2563eb',
    'topic-indigo': '#4f46e5',
    'topic-purple': '#124,58,237',
    'topic-pink': '#db2777'
  };

  return (
    <div className="rounded-xl bg-white p-3">
      <H4 className="mb-2">Themen</H4>
      {data.allPodcastThemen.nodes.map((item, i) => {
        let counter = 0;
        data.allPodcastFolgen.nodes.forEach((folge) => {
          if (folge.thema.display === item.thema) {
            counter = counter + 1;
          }
        });

        return (
          <Link to={`/podcast/folgen/${item.slug_slug}`} className={`block ${data.allPodcastThemen.nodes.length === i + 1 ? ' hover:underline' : 'mb-3 hover:underline'}`} key={i}>
            {item.thema}{' '}
            <span className={`inline-flex h-5 w-5 items-center justify-center rounded-full text-xs text-white`} style={{ backgroundColor: colorMapping[`topic-${item.farbe}`] }}>
              {counter}
            </span>
          </Link>
        );
      })}
    </div>
  );
};

export default SidebarTopics;
