import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~components/layouts/Default';
import Container from '~components/common/Container';
import GridThree from '~components/common/GridThree';
import PodcastListEpisodes from '~components/podcast/PodcastListEpisodes';
import PodcastSidebarTopics from '~components/podcast/PodcastSidebarTopics';
import PageHeader from '~components/common/PageHeader';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Alle Folgen | Entspannungshelden Podcast" desc='Alle unsere Folgen zu unserem Podcast "Entspannungshelden"' active="Podcast">
      <PageHeader title="Alle Folgen" breadcrumb={[{ title: 'Wissen', link: '/wissen/' }, { title: 'Podcast', link: '/podcast/' }, { title: 'Folgen' }]} />
      <Container noMargin className="my-8">
        <GridThree>
          <div className="md:col-span-2">
            <PodcastListEpisodes listArray={data.alleFolgen.nodes} />
          </div>
          <div>
            <div>
              <PodcastSidebarTopics />
            </div>
          </div>
        </GridThree>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    alleFolgen: allPodcastFolgen(sort: { fields: folgennummer, order: DESC }, filter: { public: { eq: true } }) {
      nodes {
        titel
        slug_slug
        thema {
          display
        }
        remoteTitelbild {
          childImageSharp {
            gatsbyImageData
          }
        }
        einleitung
        folgennummer
        datum
      }
    }
  }
`;
